export default {
  cyan: '#59f2f7',
  cyanLight: '#59f2f711',
  blue: '#74b3fb',
  blueLight: '#74b3fb11',
  blueDark: '#74b3fb',
  red: '#e56674',
  redLight: '#e5667411',
  violet: '#d49ff4',
  violetLight: '#d49ff411',
  green: '#70F49C',
  greenLight: '#70F49C11',
  yellow: '#FFC72C',
  orange: '#e9b684',
  orangeLight: '#e9b68411',
  white: '#99a3b7',
  whiteBright: '#FFFFFF',
  black: '#181A1F',
  gray: '#5C6975',
  grayLight: '#AEAEAE',
  grayDark: '#3B4656',
};
