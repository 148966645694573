module.exports = {
    "metadata": {
        "name": "ts-defold",
        "short_name": "ts-defold",
        "description": "An awesome TypeScript toolkit for leveling-up Defold.",
        "language": "en",
        "url": "https://ts-defold.dev",
        "pathPrefix": "/",
        "sentry": "https://3cd7808a26214f40b3e85e748370cee2@o918528.ingest.sentry.io/5932586",
        "siteImage": "/assets/social.png",
        "favicon": "/assets/favicon.png",
        "themeColor": "#ff0000",
        "monetization": "$ilp.uphold.com/hGqBNJM932UJ"
    },
    "header": {
        "logo": "/assets/logo.svg",
        "logoLink": "/",
        "helpUrl": "",
        "links": [
            {
                "text": "Docs",
                "link": "/",
                "external": false
            },
            {
                "text": "Playground",
                "link": "/playground",
                "external": false
            }
        ],
        "enabled": true
    },
    "sidebar": {
        "enabled": true,
        "ignoreIndex": false,
        "forcedNavOrder": [],
        "expanded": [],
        "groups": [
            {
                "order": 1,
                "path": "/gettingstarted",
                "title": ":rocket: Getting Started"
            },
            {
                "order": 2,
                "path": "/configuration",
                "title": ":wrench: Configuration"
            },
            {
                "order": 3,
                "path": "/editing",
                "title": ":writing_hand: Editing Content"
            },
            {
                "order": 4,
                "path": "/deployment",
                "title": ":rocket: Deployment"
            },
            {
                "order": 5,
                "path": "/developing",
                "title": ":computer: Developing"
            }
        ],
        "links": [
            {
                "text": "Defold",
                "link": "https://defold.com"
            },
            {
                "text": "TypeScriptToLua",
                "link": "https://typescripttolua.github.io/"
            }
        ],
        "poweredBy": {}
    },
    "pwa": {
        "enabled": false,
        "manifest": {
            "name": "BooGi",
            "short_name": "BooGi",
            "start_url": "/",
            "background_color": "#6b37bf",
            "theme_color": "#6b37bf",
            "display": "standalone",
            "crossOrigin": "anonymous",
            "icon": "/assets/favicon.png"
        }
    },
    "social": {
        "facebook": "",
        "github": "https://github.com/ts-defold/create",
        "gitlab": "",
        "instagram": "",
        "linkedin": "",
        "mail": "",
        "gmail": "",
        "slack": "",
        "twich": "",
        "twitter": "https://twitter.com/tsdefold",
        "youtube": "",
        "discord": "https://discord.gg/eukcq5m"
    },
    "features": {
        "editOnRepo": {
            "editable": true,
            "location": "https://github.com/ts-defold/ts-defold.github.io",
            "type": "github"
        },
        "search": {
            "enabled": false,
            "indexName": "docs",
            "algoliaAppId": null,
            "algoliaSearchKey": null,
            "algoliaAdminKey": null,
            "excerptSize": 8000,
            "engine": "localsearch",
            "placeholder": "Search",
            "startComponent": "icon",
            "debounceTime": 380,
            "snippetLength": 23,
            "hitsPerPage": 10,
            "showStats": true,
            "localSearchEngine": {
                "encode": "advanced",
                "tokenize": "full",
                "threshold": 2,
                "resolution": 30,
                "depth": 20
            },
            "pagination": {
                "enabled": true,
                "totalPages": 10,
                "showNext": true,
                "showPrevious": true
            }
        },
        "toc": {
            "show": true,
            "depth": 3
        },
        "previousNext": {
            "enabled": true,
            "arrowKeyNavigation": true
        },
        "scrollTop": false,
        "showMetadata": true,
        "propagateNetlifyEnv": true,
        "pageProgress": {
            "enabled": true,
            "excludePaths": [
                "/"
            ],
            "height": 3,
            "prependToBody": false,
            "color": "#A05EB5",
            "includePaths": [
                "/configuration/settingup/features"
            ]
        },
        "mermaid": {
            "language": "mermaid",
            "theme": "dark",
            "options": {},
            "width": 400,
            "height": 300
        },
        "rss": {
            "enabled": false,
            "showIcon": false,
            "title": "TS-DEFOLD: TypeScript <3 Defold",
            "copyright": "2021, Justin Walsh <@thejustinwalsh>",
            "webMaster": "Justin Walsh <@thejustinwalsh>",
            "managingEditor": "Justin Walsh <@thejustinwalsh>",
            "categories": [
                "Docs as Code",
                "GatsbyJS"
            ],
            "ttl": 60,
            "matchRegex": "^/",
            "outputPath": "/rss.xml",
            "generator": "gatsby"
        },
        "darkMode": {
            "enabled": false,
            "default": false
        },
        "publishDraft": false,
        "fullScreenMode": {
            "enabled": true,
            "hideHeader": true,
            "hideToc": true,
            "hideSidebar": true
        }
    }
};